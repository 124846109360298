import BaseModel from '../BaseModel'

export class IntegracaoModel extends BaseModel {
  constructor (params = {}) {
    super()
    this.codigo_int = params.codigo_int || null
    this.codemp_int = params.codemp_int || null
    this.codusu_int = params.codusu_int || null
    this.token_int = params.token_int || ''
    this.usuario = params.usuario || {}
    this.empresa = params.empresa || {}
  }

  toMap () {
    return {
      codigo_int: this.codigo_int,
      codemp_int: this.empresa?.codigo_emp || null,
      codusu_int: this.usuario?.codigo_usu || null,
      token_int: this.token_int || ''
    }
  }
}

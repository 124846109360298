import './_avaliacao.scss'
import React from 'react'
import { Button } from 'primereact/button'
import List from '../../../../classes/List'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/layout/DataList'
import TablePreferences from '../../../../components/utils/TablePreferences'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import {
  formatToCurrency,
  formatPlaca,
  formatDate
} from '../../../../helpers/formaters'
import AvaliacaoService from '../../../../services/cadastro/veiculo/AvaliacaoService'
import { getAvaliacaoDTO } from '../../../../dtos/vendas/negociacao/avaliacao/AvaliacaoDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import AppButton from '../../../../components/layout/AppButton'

export class AvaliacaoList extends List {
  constructor (props) {
    super(props, getAvaliacaoDTO, AvaliacaoService, 'avaliacao', 'codigo_ava')

    this.state = {
      ...this.state,
      visiblePreferencesModal: false,
      expandedRows: null

    }

    this.setColumns(createColumns([
      { field: 'apelido_emp', header: 'Empresa', width: '20%' },
      { field: 'descricao_vei', header: 'Veículo', width: '20%', customBody: this.getDescricaoVeiculo },
      { field: 'nome_ven', header: 'Avaliador', width: '30%' },
      { field: 'dathor_ava', header: 'Data', width: '15%', customBody: this.getData },
      { field: 'codfoa_ava', header: 'Aquisição', width: '15%', customClassName: this.getAquisicaoClass, customBody: this.getAquisicao },
      { field: 'valor_ava', header: 'Valor', width: '20%', customBody: this.getValorAvaliacao }
    ]))
  }

  getDescricaoVeiculo (data) {
    return `${formatPlaca(data.placa_ava !== null ? data.placa_ava : '')} ${data.descri_mod !== null ? data.descri_mod : ''}`
  }

  getPlaca (data) {
    return formatPlaca(data.placa_ava)
  }

  getAquisicaoClass (data) {
    return `aquisicao-${data?.codfoa_ava}`
  }

  getData (data) {
    return formatDate(data.dathor_ava)
  }

  getAquisicao (data) {
    let codfoa = data?.codfoa_ava
    let aquisicao = { 1: 'Troca', 2: 'Compra', 3: 'Consignação', null: 'Não informado' }
    return aquisicao[codfoa]
  }

  getValorAvaliacao (data) {
    return formatToCurrency(data.valor_ava)
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Ano</label>
        <div className="formgrid grid">
          <div className="col-6">
            <InputText placeholder="de" name="anoModeloInicial" value={this.state.filter.anoModeloInicial}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <InputText placeholder="até" name="anoModeloFinal" value={this.state.filter.anoModeloFinal}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-4">
        <label className="label">Preço</label>
        <div className="formgrid grid">
          <div className="col-6">
            <InputText placeholder="de" name="precoInicial" value={this.state.filter.precoInicial}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <InputText placeholder="até" name="precoFinal" value={this.state.filter.precoFinal}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-4">
        <label className="label">Quilometragem</label>
        <div className="formgrid grid">
          <div className="col-6">
            <InputText placeholder="de" name="kmInicial" value={this.state.filter.kmInicial}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <InputText placeholder="até" name="kmFinal" value={this.state.filter.kmFinal}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <AppButton label="Buscar" icon="pi pi-search" onClick={this.onFilter} />
      </div>
    </>
  )

  rowExpansionTemplate = (data) => {
    return (
      <div className={'visualizar-avaliacao row-expansion grid justify-content-' + (CommonHelper.isDesktop() ? 'end' : 'center')}>
        <AppButton
          label="Visualizar"
          onClick={() => this.onView(data)}
          className="visualizar-avaliacao button"
        />
      </div>
    )
  }

  render () {
    const { rows, totalRecords, first } = this.state
    const columns = this.columns

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Avaliações</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="tabel-options">
            <div
              className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}
            >
              <span className="option-label">Todas</span>
              <div className="option-quantity">
                {this.state.totalRecords}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={this.state.list}
            selected={this.state.selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={this.state.page}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            showFilter={true}
            filterPlaceholder="Procurar Avaliações"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            rowExpansionTemplate={this.rowExpansionTemplate}
            expandedRows={this.state.expandedRows}
            // expandedFiltersTemplate={this.expandedFiltersTemplate}
          >
            {getColunasHabilitadas(columns, 'avalicao')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de avaliação"
          tableName="avaliacao"
          columns={columns}
          visible={this.state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}

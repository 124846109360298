import React, { Component } from 'react'
import { Button } from 'primereact/button'
import SelectInput from '../../../components/inputs/SelectInput'
import Container from '../../../components/layout/Container'
import EmpresasService from '../../../services/cadastro/pessoa/EmpresasService'
import UsuarioService from '../../../services/cadastro/pessoa/UsuarioService'
import TextInput from '../../../components/inputs/TextInput'
import IntegracoesService from '../../../services/Integracao/IntegracoesService'
import { showErrorMessage, showMessage, showSuccessMessage } from '../../../components/utils/Message'
import { withRouter } from 'react-router-dom'
import CommonHelper from '../../../helpers/CommonHelper'
import PanelContainer from '../../../components/layout/PanelContainer'

class IntegracaoForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      edicaoHabilitada: false,
      empresas: [],
      usuarios: [],
      enviandoEmail: false,
      salvando: false
    }
  }

  componentDidMount () {
    if (this.props.isNew) {
      this.setState({ edicaoHabilitada: true })
    }

    this.getEmpresas()
    this.getUsuarios()
  }

  getEmpresas = async () => {
    try {
      const empresas = await EmpresasService.getAllEmpresas()

      this.setState({ empresas })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as empresas!')
    }
  }

  getUsuarios = async () => {
    try {
      const usuarios = await UsuarioService.getAllUsers()

      this.setState({ usuarios })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os usuários!')
    }
  }

  render () {
    const { model } = this.props
    const { empresas, edicaoHabilitada } = this.state

    return (
      <Container col="12" className="Integração">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>Cadastro de Integração {model.codigo_int ? `- ${model.codigo_int}` : ''}</h1>
        </div>
        <div className="grid" style={{ minWidth: '314px' }}>
          <PanelContainer header="CAPA" style={{ paddingTop: '2em', minWidth: '300px' }} className="col-12" toggleable>
            <div className="formgrid grid">
              <div className="field col-12 md:col-6">
                <SelectInput
                  label="Empresa"
                  value={model.empresa}
                  options={empresas}
                  onChange={this.handleChangeEmpresa}
                  optionLabel="pessoa.apelid_pes"
                  dataKey="codigo_emp"
                  noFloatLabel
                  disabled={!edicaoHabilitada}
                  className="inputfield w-full"
                  showClear={model.empresa?.codigo_emp}
                />
              </div>
              <div className="field col-12 md:col-6">
                <label>Usuário</label>
                <SelectInput
                  value={model.usuario?.email && model.usuario}
                  options={this.state.usuarios}
                  onChange={this.onSelectUsuario}
                  name="usuario"
                  filter={true}
                  optionLabel="email"
                  dataKey="codigo_usu"
                  noFloatLabel
                  disabled={!edicaoHabilitada}
                  className="inputfield w-full"
                  showClear={model.usuario?.codigo_usu}
                />
              </div>
              <div className="field col-12">
                <TextInput
                  label="Token"
                  name="token_int"
                  placeholder="Token"
                  value={model.token_int}
                  readOnly={true}
                  className="inputfield w-full"
                />
              </div>
            </div>
          </PanelContainer>
        </div>
        <div className="flex justify-content-end salvar" style={{ paddingTop: '1em', paddingBottom: '1em' }}>
          {edicaoHabilitada && (
            <Button
              label="Salvar"
              className="p-button-primary"
              onClick={this.salvarIntegracao}
              disabled={this.state.salvando}
            />
          )}
          {!edicaoHabilitada && (
            <Button
              label="Reenviar e-mail"
              className="p-button-primary"
              onClick={this.enviarEmail}
              disabled={this.state.enviandoEmail}
            />
          )}
        </div>
      </Container>
    )
  }

  handleChange = (event) => {
    const model = this.props.model
    model[event.target.name] = event.target.value
    this.props.onChange(model)
  }

  handleChangeEmpresa = (event) => {
    const { model } = this.props
    model.empresa = event.target.value
    this.props.onChange(model)
  }

  onSelectUsuario = (e) => {
    const { model } = this.props
    model.usuario = e.target.value
    this.props.onChange(model)
  }

  gerarToken = async () => {
    const { model } = this.props
    const token = await IntegracoesService.gerarToken(model.usuario?.codigo_usu)

    model.token_int = token
    this.props.onChange(model)
  }

  salvarIntegracao = async () => {
    try {
      const { model } = this.props

      const valores = [model.usuario?.codigo_usu, model.empresa?.codigo_emp]
      const validacoes = ['notEmpty', 'notEmpty']
      const mensagens = ['Informe um usuário!', 'Informe a empresa!']
      const camposValidos = CommonHelper.validarCampos(valores, validacoes, [], mensagens)

      if (camposValidos.erro) {
        const mensagens = []

        camposValidos.mensagens.forEach((item) => {
          mensagens.push({ severity: 'warn', summary: 'Campo inválido', detail: item.mensagem })
        })

        showMessage(mensagens)
      } else {
        this.setState({ salvando: true })

        const integracoesService = new IntegracoesService()
        const integracao = await integracoesService.save(model)

        if (integracao.codigo_int && this.props.isNew) {
          this.props.history.push(`/integracao/${integracao.codigo_int}`)
        }

        model.token_int = integracao.token_int
        model.codigo_int = integracao.codigo_int
        this.props.onChange(model)

        this.setState({ salvando: false, edicaoHabilitada: false })
        showSuccessMessage('Integração salva com sucesso! Um e-mail com o token foi enviado ao usuário')
      }
    } catch (error) {
      this.setState({ salvando: false })
      showErrorMessage(error.message || 'Erro ao salvar integração')
    }
  }

  enviarEmail = async () => {
    try {
      const { model } = this.props

      this.setState({ enviandoEmail: true })
      await IntegracoesService.enviarEmailIntegracao(model.usuario?.codigo_usu)
      this.setState({ enviandoEmail: false })

      showSuccessMessage('Um e-mail com o token foi enviado ao usuário!')
    } catch (error) {
      this.setState({ enviandoEmail: false })
      showErrorMessage(error.message || 'Ocorreu um erro ao enviar o e-mail de integração')
    }
  }
}

export default withRouter(IntegracaoForm)

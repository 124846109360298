import { formatDateToAmerican } from '../../../helpers/formaters'

export const getVeiculoDTO = (data = {}) => {
  let descricaoConcatenada = ''
  if (Array.isArray(data.acessorios)) {
    let acessoriosOrdenados = data.acessorios?.sort((a, b) => {
      return a.acessorio.descri_ace.localeCompare(b.acessorio.descri_ace)
    })
    acessoriosOrdenados?.forEach(acessorioVei => {
      descricaoConcatenada += acessorioVei.acessorio.descri_ace + ', '
    })
    // Remover a última vírgula e espaço extra
    descricaoConcatenada = descricaoConcatenada.slice(0, -2)
  }

  return {
    codigo_vei: data.codigo_vei || null,
    placa_vei: data.placa_vei || null,
    plaant_vei: data.plaant_vei || '',
    renava_vei: data.renava_vei || null,
    chassi_vei: data.chassi_vei || null,
    anofab_vei: data.anofab_vei || null,
    anomod_vei: data.anomod_vei || null,
    cappot_vei: data.cappot_vei || null,
    cidade_vei: data.cidade_vei || null,
    datinigar_vei: (data.datinigar_vei != null ? new Date(data.datinigar_vei) : null),
    datfimgar_vei: (data.datfimgar_vei != null ? new Date(data.datfimgar_vei) : null),
    datven_vei: (data.datven_vei != null ? new Date(data.datven_vei) : null),
    tipoVeiculo: (data.codtiv_vei === 'u' ? 'Usado' : 'Novo'),
    codtiv_vei: data.codtiv_vei,
    uf_vei: data.uf_vei || null,
    ufant_vei: data.ufant_vei || '',
    obs_vei: data.obs_vei || '',
    cilind_vei: data.cilind_vei || null,
    carmod_vei: data.carmod_vei || null,
    km_vei: data.km_vei || null,
    nummot_vei: data.nummot_vei || null,
    unicil_vei: data.unicil_vei === 2 || data.unicil_vei === true ? true : false,
    repass_vei: data.repass_vei || false,
    codfip_vei: data.codfip_vei || '',
    qtdpor_vei: data.qtdpor_vei || 0,
    proprietario: data.proprietario || { nomraz_pes: '' },
    //modelo: data.modelo || { descri_mod: '' },
    modelo: {
      ...data.modelo,
      descri_mod: data.modelo?.descri_mod || '',
      tipoVeiculoDenatran: data.modelo?.tipoVeiculoDenatran || null
    },
    cor: data.cor || {},
    corInterna: data.corInterna || {},
    corExterna: data.corExterna || {},
    tipoPintura: data.tipoPintura || {},
    combustivel: data.combustivel || {},
    categoria: data.categoria || {},
    cambio: data.cambio || {},
    classeICMS: data.produto?.classeICMS || {},
    grupoProduto: data.produto?.grupo || {},
    origemProduto: data.produto?.origem || {},
    classificacaoFiscal: data.produto?.classificacaoFiscal || {},
    produto: {
      ...data.produto,
      codgrp_pro: data.produto?.codgrp_pro || null,
      codorp_pro: data.produto?.codorp_pro || 0,
      codcla_pro: data.produto?.codcla_pro || 5,
      codclf_pro: data.produto?.codclf_pro || null,
      pesbru_pro: data.produto?.pesbru_pro || 0,
      pesliq_pro: data.produto?.pesliq_pro || 0,
      datreg_pro: (data.produto?.datreg_pro != null ? new Date(data.produto?.datreg_pro) : null),
      ultalt_pro: (data.produto?.ultalt_pro != null ? new Date(data.produto?.ultalt_pro) : null),
      produtoEmpresa: data.produto?.produtoEmpresas ? data.produto?.produtoEmpresas[0] : {}
    } || {},
    qtdval_vei: data.qtdval_vei || 0,
    acessorios: data.acessorios || [],
    descricoesAcessorios: descricaoConcatenada || '',
    estado: data.estado || {},
    estadoAnterior: data.estadoAnterior || {},
    charem_vei: data.charem_vei || false,
    adadef_vei: data.adadef_vei || false,
    blinda_vei: data.blinda_vei || false,
    ofertaEmpresa: data.ofertaEmpresa || {},
    usacom_tvd: data.usacom_tvd || 1,
    fipcoc_vei: data.fipcoc_vei || null,
    codcoc_vei: data.codcoc_vei || null,
    descoc_vei: data.descoc_vei || '',
    tivcoc_vei: data.tivcoc_vei || null,
    desfip_vei: data.desfip_vei || '',
    datfip_vei: data.datfip_vei ? formatDateToAmerican(data.datfip_vei) : null,
    valfip_vei: data.valfip_vei || 0,
    obrigaCilindrada: true
  }
}

export const postVeiculoDTO = (data) => {
  return {
    codigo_vei: data.codigo_vei || null,
    placa_vei: data.placa_vei || null,
    plaant_vei: data.plaant_vei || '',
    renava_vei: data.renava_vei || null,
    chassi_vei: data.chassi_vei || null,
    anofab_vei: data.anofab_vei || null,
    anomod_vei: data.anomod_vei || null,
    cappot_vei: data.cappot_vei || null,
    cidade_vei: data.cidade_vei || null,
    cilind_vei: data.cilind_vei || null,
    carmod_vei: data.carmod_vei || null,
    datinigar_vei: data.datinigar_vei || null,
    datfimgar_vei: data.datfimgar_vei || null,
    datven_vei: data.datven_vei || null,
    tipoVeiculo: data.tipoVeiculo || null,
    km_vei: data.km_vei || null,
    nummot_vei: data.nummot_vei || null,
    repass_vei: data.repass_vei || false,
    codfip_vei: data.codfip_vei || '',
    qtdpor_vei: data.qtdpor_vei || 0,
    qtdval_vei: data.qtdval_vei || 0,
    proprietario: data.proprietario || {},
    //modelo: data.modelo || {},
    modelo: {
      ...data.modelo,
      descri_mod: data.modelo?.descri_mod || '',
      tipoVeiculoDenatran: data.modelo?.tipoVeiculoDenatran || null
    },
    produto: data.produto || {},
    estado: data.estado || {},
    estadoAnterior: data.estadoAnterior || {},
    acessorios: data.acessorios || [],
    codcat_vei: data.categoria?.codigo_cat || null,
    unicil_vei: data.unicil_vei ? 2 : 1,
    charem_vei: data.charem_vei || false,
    adadef_vei: data.adadef_vei || false,
    blinda_vei: data.blinda_vei || false,
    codcor_vei: data.cor.codigo_cor || null,
    codcom_vei: data.combustivel.codigo_com || null,
    corint_vei: data.corInterna?.codigo_cor || null,
    codtpp_vei: data.tipoPintura.codigo_tpp || null,
    codcam_vei: data?.cambio?.codigo_cam || null,
    uf_vei: data.estado?.sigla_uf || null,
    ufant_vei: data.estadoAnterior?.sigla_uf || '',
    obs_vei: data.obs_vei || '',
    codtiv_vei: data.codtiv_vei,
    usacom_tvd: data.usacom_tvd || 1,
    fipcoc_vei: data.fipcoc_vei || null,
    codcoc_vei: data.codcoc_vei || null,
    descoc_vei: data.descoc_vei || '',
    tivcoc_vei: data.tivcoc_vei || null,
    desfip_vei: data.desfip_vei || '',
    datfip_vei: data.datfip_vei ? formatDateToAmerican(data.datfip_vei) : null,
    valfip_vei: data.valfip_vei || 0
  }
}

import * as Yup from 'yup'

export const VeiculoValidator = Yup.object().shape({
  anofab_vei: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  anomod_vei: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  cilind_vei: Yup.number()
    .nullable().test('obrigaCilindrada', 'Este campo é obrigatório!', function (value) {
      if (this.parent.obrigaCilindrada && !value) {
        return false
      }
      return true
    }),
  usacom_tvd: Yup.number(),
  proprietario: Yup.object().shape({
    codigo_pes: Yup.number()
      .nullable()
      .required('Este campo é obrigatório!')
  }),
  combustivel: Yup.object().shape({
    codigo_com: Yup.number()
      .nullable().test('usaCombustivel', 'Este campo é obrigatório!', function (value) {
        if (this.parent.usacom_tvd && !value) {
          return false
        }
        return true
      })
  }),
  cor: Yup.object().shape({
    codigo_cor: Yup.number()
      .nullable()
      .required('Este campo é obrigatório!')
  }),
  produto: Yup.object().shape({
    codcla_pro: Yup.number()
      .nullable()
      .required('Este campo é obrigatório!')
  }),
  categoria: Yup.object().shape({
    codigo_cat: Yup.number()
      .nullable()
      .required('Este campo é obrigatório!')
  }),
  modelo: Yup.object().shape({
    codigo_mod: Yup.number()
      .nullable()
      .required('Este campo é obrigatório!')
  }),
  chassi_vei: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  tipoPintura: Yup.object().shape({
    codigo_tpp: Yup.string()
      .nullable()
      .required('Este campo é obrigatório!')
  })
})

import { transformAll } from '@overgear/yup-ast'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import AppButton from '../components/layout/AppButton'
import Container from '../components/layout/Container'
import DropdownMenu from '../components/layout/DropdownButton/DropdownMenu'
import Page from '../components/layout/Page'
import { baseForm } from '../components/utils/BaseForm'
import Confirm from '../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../components/utils/Message'
import CommonHelper from '../helpers/CommonHelper'

export function Crud ({
  match,
  onSaveModal,
  onDeleteModal,
  codigoSelecionado,
  onHide,
  service,
  modelGetDTO,
  primarykeyname,
  numeroKeyName,
  resource,
  formContent,
  formTitle,
  apenasVisualizacao,
  valida,
  mostrarBotaoSalvar = true
}) {
  const modelService = new service()
  const FormContent = formContent

  const [isNew, setIsNew] = useState(false)
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const [validator, setValidator] = useState({})
  const [camposObrigatorios, setCamposObrigatorios] = useState(null)
  const [header, setHeader] = useState(null)
  const [footer, setFooter] = useState(null)
  const [actions, setActions] = useState(null)
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [editar, setEditar] = useState(false)
  const [mostrarBotaoAcoes, setMostrarBotoesAcoes] = useState(true)
  const history = useHistory()

  const form = baseForm({
    initialValues: modelGetDTO(),
    validationSchema: validator
  })

  useEffect(async () => {
    const id = match?.params?.id

    if (id || codigoSelecionado) {
      await getModel(id || codigoSelecionado)
      setEditar(true)
    } else {
      setIsNew(true)
      setEdicaoHabilitada(true)
    }

    await getFormValidator()
  }, [])

  function habilitaButtonEditar () {
    if (edicaoHabilitada) {
      setEditar(true)
      setEdicaoHabilitada(false)
    } else {
      setEdicaoHabilitada(true)
      setEditar(false)
    }
  }

  async function getModel (id) {
    try {
      const model = modelGetDTO(await modelService.get(id))

      await form.setValues(model)
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao obter informações')
    }
  }

  async function refreshForm () {
    await getModel(form.values.codigo_opv)
  }

  async function getFormValidator () {
    try {
      let formValidatorAST
      let formValidator
      if (valida) {
        setValidator(valida)
        formValidatorAST = valida
        formValidator = valida
      } else {
        setValidator(formValidator)
        formValidatorAST = await modelService.getFormValidator()
        formValidator = transformAll(formValidatorAST)
      }

      const camposObrigatorios = []
      getCamposObrigatoriosRecursively(formValidator.fields, camposObrigatorios)

      setCamposObrigatorios(camposObrigatorios)
      setLoading(false)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado!')
    }
  }

  function getCamposObrigatoriosRecursively (fields, camposObrigatorios, parentFieldName) {
    for (const fieldName of Object.getOwnPropertyNames(fields)) {
      if (fields[fieldName].fields) {
        let newParentFieldName = fieldName

        if (parentFieldName) {
          newParentFieldName = `${parentFieldName}.${fieldName}`
        }

        getCamposObrigatoriosRecursively(fields[fieldName].fields, camposObrigatorios, newParentFieldName)
        continue
      }

      if (parentFieldName) {
        camposObrigatorios.push(`${parentFieldName}.${fieldName}`)
        continue
      }

      camposObrigatorios.push(fieldName)
    }
  }

  async function onSubmit (data) {
    try {
      const savedObject = await modelService.save(data)
      if (onSaveModal) {
        await onSaveModal(savedObject)
        onHide()
      } else {
        history.push(`/${history.location.pathname.split('/')[1]}/${savedObject[primarykeyname]}`)
      }

      setIsNew(false)
      setEdicaoHabilitada(false)
      setEditar(false)
      await getModel(savedObject[primarykeyname])

      showSuccessMessage('Registro salvo com sucesso!')

      return savedObject[primarykeyname]
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao salvar registro')
    }
  }

  async function onSave (data) {
    setEditar(false)
    form.handleSubmit()

    const errors = await form.validateForm(data)

    if (Object.keys(errors).length !== 0) {
      showWarnMessage('Campos obrigatórios não preenchidos!')
      return
    } else {
      return await onSubmit(data)
    }
  }

  async function onDelete () {
    try {
      await modelService.delete(form.values[primarykeyname])

      if (onDeleteModal) {
        onDeleteModal()
        onHide()
      } else {
        history.push(`/${resource}`)
      }

      showSuccessMessage('Registro deletado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar excluir o registro')
    }
  }

  return (
    <Page>
      {!loading && (
        <Container col="12">
          <div className={`flex form-header justify-content-${onHide ? 'end' : 'between'} ${(!CommonHelper.isDesktop() && actions) ? 'align-items-end' : ''}`}>
            {!onHide && (
              <>
                {header ? (<>{header}</>) : (<h1>{formTitle} {form.values[numeroKeyName || primarykeyname] ? `- ${form.values[numeroKeyName || primarykeyname]}` : ''}</h1>)}
              </>
            )}
            {(!isNew && !apenasVisualizacao) && (
              <>
                {mostrarBotaoAcoes ? actions ? editar
                  ? <div className="flex justify-content-center align-items-center md:w-2 w-3">
                    <div className='md:w-7'>
                      <AppButton
                        type="button"
                        label={CommonHelper.isDesktop() ? 'Editar' : ''}
                        className="mr-2"
                        icon="pi pi-pencil"
                        onClick={() => habilitaButtonEditar(!edicaoHabilitada)}
                      />
                    </div>
                    <DropdownMenu
                      items={actions}
                      icon="pi pi-bars"
                      label={CommonHelper.isDesktop() ? 'Ações' : ''}
                      disabled={edicaoHabilitada} //Se está em edição, não deve listar as actions
                    />
                  </div>
                  : (
                    <div className="flex justify-content-end align-items-center md:w-2 w-3">
                      <DropdownMenu
                        items={actions}
                        icon="pi pi-bars"
                        label={CommonHelper.isDesktop() ? 'Ações' : ''}
                        disabled={edicaoHabilitada} //Se está em edição, não deve listar as actions
                      />
                    </div>

                  ) : (
                  <div className="flex">
                    <AppButton
                      label="Excluir"
                      icon="pi pi-times"
                      type="button"
                      onClick={() => setVisibleConfirmModal(true)}
                      className="p-button-danger mr-2"
                      disabled={edicaoHabilitada}
                    />
                    <AppButton
                      type="button"
                      label="Editar"
                      className="edit-button"
                      icon="pi pi-pencil"
                      onClick={() => setEdicaoHabilitada(!edicaoHabilitada)}
                      disabled={edicaoHabilitada}

                    />
                  </div>
                ) : ''}
              </>
            )}
          </div>
          <div className="grid pt-3">
            <FormContent
              form={form}
              edicaoHabilitada={edicaoHabilitada}
              setEdicaoHabilitada={(edicaoHabilitada) => setEdicaoHabilitada(edicaoHabilitada)}
              camposObrigatorios={camposObrigatorios}
              isNew={isNew}
              setActions={setActions}
              setEditar={setEditar}
              setHeader={setHeader}
              setFooter={setFooter}
              onSave={onSave}
              refreshForm={refreshForm}
              setMostrarBotoesAcoes={setMostrarBotoesAcoes}
            />
          </div>

          {edicaoHabilitada && (
            <>
              {mostrarBotaoSalvar ? footer
                ? <>{footer}</>
                : (
                  <div className="flex justify-content-end pb-6 pt-3">
                    <AppButton
                      type="submit"
                      label="Salvar"
                      className="p-button"
                      onClick={async () => await onSave(form.values)}
                    />
                  </div>
                )
                : ''}
            </>
          )}
        </Container>
      )}
      <Confirm
        visible={visibleConfirmModal}
        onConfirm={() => onDelete()}
        onCancel={() => setVisibleConfirmModal(false)}
        title="Confirmação"
        description="Deseja realmente excluir este registro?"
      />
    </Page>
  )
}

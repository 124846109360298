import moment from 'moment'

export default class CommonHelper {
  static isDesktop () {
    return window.innerWidth > 1024
  }

  static isNotebook () {
    return window.innerWidth < 1900
  }

  static removerAcentosETracos (str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').
      replace(/[\u0300-\u036f]/g, '') // Remove diacríticos
      .replace(/-/g, '')
  }

  static ajustarDataParaDiaUtil (diasParaAdicionar) {
    let dataAtual = new Date()
    let diasAdicionados = 0
    const domingo = 0
    const sabado = 6

    while (diasAdicionados < diasParaAdicionar) {
      dataAtual.setDate(dataAtual.getDate() + 1)

      if (dataAtual.getDay() !== domingo && dataAtual.getDay() !== sabado) {
        diasAdicionados++
      }
    }
    return dataAtual.toDateString()
  }

  static removerCaracteresEspeciais (texto) {
    let padrao = /Alt\+(1[0-9]|[1-9])=/g
    texto = texto.replace(/\t/g, ' ')
    texto = texto.replace(/●/g, '•')

    texto = texto.replace(padrao, '')
    return texto
  }

  static formataData (data) {
    return data ? moment.utc(data).format('DD/MM/YYYY') : ''
  }

  static currencyToInteger (value) {
    if (!value) return 0

    return parseInt(String(value * 100).split('.')[0])
  }

  static integerToCurrency (value) {
    if (!value) return null

    return Number(value) / 100
  }

  /**
   * Objetivo: Arredondar um número conforme as casas decimais
   * Parâmetros: <valor> Valor que será arredondado, <casasDecimais> Número de casas decimais
   * @param valor Valor numérico
   * @param casasDecimais Número de casas decimais que será arredondado
   */
  static Round (valor, casasDecimais) {
    const fatorMultiplicador = Math.pow(10, casasDecimais)
    return Math.round(Number(valor) * fatorMultiplicador) / fatorMultiplicador
  }

  /**
   * Ajuste os valores de uma coluna até que a soma da coluna fique igual ao ValorFixo.
   * @param arrayDados Array com os dados. Este array é passado por referência
   * @param nomeColuna Nome da coluna que será feito o ajuste
   * @param valorFixo Valor que a soma dos iten deverá atingir
   * @param valorAjuste Valor do ajuste. Ex: 0.01
   * @param condicaoAjuste Condição para fazer o ajuste. Ex: 'item["iscan_peo"] == false'
   */
  static ajustaValorColuna (
    arrayDados,
    nomeColuna,
    valorFixo,
    valorAjuste,
    condicaoAjuste
  ) {
    let somaColuna = 0
    let acaoMat
    let diferenca

    if (condicaoAjuste !== undefined) {
      somaColuna = arrayDados.reduce((acumulador, item) => {
        if (eval(condicaoAjuste)) {
          return acumulador + item[nomeColuna]
        } else {
          return acumulador
        }
      }, 0)
    } else {
      somaColuna = arrayDados.reduce((sum, item) => sum + item[nomeColuna], 0)
      condicaoAjuste = 'true' // Definindo uma condição padrão caso não seja fornecida
    }

    // Aumentar, diminuir, nenhum.
    if (somaColuna < valorFixo) {
      acaoMat = 'a'
    } else if (somaColuna > valorFixo) {
      acaoMat = 'd'
    } else {
      acaoMat = 'n'
    }

    let qtdDecimais = this.quantidadeCasasDecimais(valorAjuste)
    diferenca = this.Round(Math.abs(valorFixo - somaColuna), qtdDecimais)

    if (['a', 'd'].includes(acaoMat)) {
      // inclui diferenca > valorAjuste, pois estava dando 1 centavo de diferença em alguns casos
      while (diferenca > 0) {
        for (const item of arrayDados) {
          if (eval(condicaoAjuste)) {
            if (acaoMat === 'a') {
              item[nomeColuna] += valorAjuste
            } else {
              item[nomeColuna] -= valorAjuste
            }
            diferenca = this.Round(diferenca - valorAjuste, qtdDecimais)
            if (diferenca <= 0) break
          }
        }
      }
    }
  }

  /**
   * Calcula a quantidade de casas decimais do número
   * @param numero Número que será calculado a quantidade de casas decimais
   */
  static quantidadeCasasDecimais (numero) {
    return numero.toString().split('.')[1]?.length || 0
  }

  static dateToAmerican (date) {
    if (!date) return null

    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  static dateTimeToNumber (date) {
    if (!date) return null
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDDHHmmss')
  }

  static dateToBrazilian (date) {
    if (!date) return null

    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
  }

  static dateTimeToBrazilian (date) {
    if (!date) return null

    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
  }

  static todayToAmerican () {
    return moment().format('YYYY-MM-DD')
  }

  static todayToBrazilian () {
    return moment().format('DD/MM/YYYY')
  }

  static currentTime () {
    return moment().format('HH:mm:ss')
  }

  static lastMonthToDate () {
    return moment().subtract(1, 'M').toDate()
  }

  static lastDayOfPreviousMonthToDate () {
    return moment().subtract(1, 'M').endOf('M').toDate()
  }

  static lastDayOfMonthToDate () {
    return moment().endOf('M').toDate()
  }

  static nextMonthToAmerican () {
    return moment().add(1, 'M').format('YYYY-MM-DD')
  }

  static nextMonthToBrazilian () {
    return moment().add(1, 'M').format('DD/MM/YYYY')
  }

  static isValidDate (date) {
    return date instanceof Date && !isNaN(date)
  }

  static getCurrentYear () {
    return moment().toDate().getFullYear()
  }

  static getCurrentMonth () {
    return moment().toDate().getMonth()
  }

  static isSameMonthAndYear (date) {
    const inputDate = moment(date, [moment.ISO_8601, 'YYYY-MM-DD'], true)
    const currentDate = moment()

    if (!inputDate.isValid()) {
      return false
    }

    return (
      inputDate.isSame(currentDate, 'month') &&
      inputDate.isSame(currentDate, 'year')
    )
  }

  static getMargem (valorCusto, valorVenda, isMarkup = false) {
    if (!valorCusto || !valorVenda) {
      return 0.0
    }

    if (isMarkup) {
      return ((valorVenda - valorCusto) / valorCusto) * 100
    } else {
      return ((valorVenda - valorCusto) / valorVenda) * 100
    }
  }

  static clearDigitsSpecialChars (value) {
    if (value) {
      const anyNonDigitRegExp = /[^0-9]/g
      return value.toString().replace(anyNonDigitRegExp, '')
    }

    return ''
  }

  static clearDigitsSpecialCharsExceptLetters (value) {
    if (value) {
      const anyNonDigitRegExp = /[^a-zA-Z0-9]/g
      return value.toString().replace(anyNonDigitRegExp, '')
    }

    return ''
  }

  static removeBlankSpaces (value) {
    if (value) {
      return value.replace(/\s\s+/g, ' ').trimEnd()
    }

    return ''
  }

  static formatDocument (value) {
    if (!value) return ''

    if (value.length > 14) {
      return this.formatCnpj(value)
    }

    return this.formatCpf(value)
  }

  static formatCpf (value) {
    let rawValue = this.clearDigitsSpecialChars(value)

    let newValue = rawValue.substr(0, 3)

    if (rawValue.length > 3) {
      newValue += '.' + rawValue.substr(3, 3)
    }

    if (rawValue.length > 6) {
      newValue += '.' + rawValue.substr(6, 3)
    }

    if (rawValue.length > 9) {
      newValue += '-' + rawValue.substr(9, 2)
    }

    return newValue
  }

  static formatCnpj (value) {
    let rawValue = this.clearDigitsSpecialChars(value)

    let newValue = rawValue.substr(0, 2)

    if (rawValue.length > 2) {
      newValue += '.' + rawValue.substr(2, 3)
    }

    if (rawValue.length > 5) {
      newValue += '.' + rawValue.substr(5, 3)
    }

    if (rawValue.length > 8) {
      newValue += '/' + rawValue.substr(8, 4)
    }

    if (rawValue.length > 12) {
      newValue += '-' + rawValue.substr(12, 2)
    }

    return newValue
  }

  static ordenarPorChave = (lista, chave) => {
    lista.sort(function (a, b) {
      // eslint-disable-next-line
      let nomeA = eval(`a.${chave}`).toUpperCase(); // Ignora maiusculas e min
      // eslint-disable-next-line
      let nomeB = eval(`b.${chave}`).toUpperCase(); // Ignora maiusculas e min
      if (nomeA < nomeB) {
        return -1
      }
      if (nomeA > nomeB) {
        return 1
      }

      // nomes devem ser iguais
      return 0
    })
  };

  static formatPlaca = (strPlaca) => {
    return strPlaca.replace(/([A-Z]{3})([0-9][0-9A-Z][0-9]{2})/, '$1-$2')
  };

  /**
   * Reliaza o download de um arquivo
   * @param {string} dataType Tipo do dado no formato *\/*
   * @param {object} blob Objeto Blob com o arquivo a ser baixado
   * @param {string} filename Nome do arquivo
   */
  static openFile = (dataType, blob, filename) => {
    /*
    let element = document.createElement('a')

    element.setAttribute('target', '_blank')
    element.setAttribute('download', filename)
    element.setAttribute('href', window.URL.createObjectURL(blob))

    element.dataset.downloadurl = [dataType, element.download, element.href].join(':')

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()
    element.remove()
*/
    var pdfBlob = new Blob([blob], { type: dataType })

    var save = document.createElement('a')
    save.href = window.URL.createObjectURL(pdfBlob)
    save.target = '_blank'
    save.download = filename

    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search('Chrome') < 0
    ) {
      document.target = '_blank'
      document.location = save.href
    } else {
      window.open(save.href)
    }
  };

  /**
   * Valida o campo de acordo com o critério informado. Caso
   * @param {Array<string | number>} valores Valores que será validado
   * @param {Array<
   * 'notEmpty' |
   * 'biggerThenZero' |
   * 'afterToday' |
   * 'zeroOrGreater' |
   * 'equalsTo' |
   * 'conditionalNotEmpty'>} validacoes Tipos de validações que serão feitas
   * @param {Array<string>} nomeCampos Nome dos campos para o usuário
   * @param {Array<string>} mensagens Mensagens que serão exibidas caso ocorra um erro
   * @returns Boolean
   * @throws Error()
   */
  static validarCampos = (valores, validacoes, nomeCampos, mensagens) => {
    if (!valores || !validacoes) {
      console.error('Lista de valores ou validacoes é undefined ou null')
      throw Error('Ops, houve um problema ao validar os campos!')
    }

    let response = {
      erro: false,
      mensagens: []
    }

    valores.forEach((valor, idx) => {
      if (!validacoes[idx]) {
        console.error('Campo validação é undefined ou null')
        throw Error('Ops, houve um problema ao validar os campos!')
      }

      let item = {
        valor: valor,
        validacao: validacoes[idx],
        nomeCampo: nomeCampos ? nomeCampos[idx] : '',
        mensagem: ''
      }

      switch (validacoes[idx]) {
      case 'notEmpty':
        if (!valor || valor.toString().trim() === '') {
          const mensagemPadrao = `O campo ${nomeCampos ? nomeCampos[idx] : ''
          } não pode ser vazio!`
          item.mensagem = mensagens ? mensagens[idx] : mensagemPadrao

          response.mensagens.push(item)
        }
        break

      case 'biggerThenZero':
        if (valor <= 0) {
          const mensagemPadrao = `O campo ${nomeCampos ? nomeCampos[idx] : ''
          } não pode ser igual a zero!`
          item.mensagem = mensagens ? mensagens[idx] : mensagemPadrao

          response.mensagens.push(item)
        }
        break

      case 'zeroOrGreater':
        if (valor < 0) {
          const mensagemPadrao = `O campo ${nomeCampos ? nomeCampos[idx] : ''
          } deve ser maior ou igual a zero!`
          item.mensagem = mensagens ? mensagens[idx] : mensagemPadrao

          response.mensagens.push(item)
        }
        break

      case 'afterToday':
        // if (moment(valor).diff(moment(), 'hours') < 0) {
        //     const mensagemPadrao = `O campo ${nomeCampos ? nomeCampos[idx] : ""} não pode ser menor que a data de hoje!`
        //     item.mensagem = mensagens ? mensagens[idx] : mensagemPadrao

        //     response.mensagens.push(item)
        // }

        break

      case 'equalsTo':
        if (valor instanceof Array) {
          if (valor[0] !== valor[1]) {
            const mensagemPadrao = `Os campos ${nomeCampos ? nomeCampos[idx][0] : ''
            } 
                            e ${nomeCampos ? nomeCampos[idx][1] : ''
                } devem ser iguais!`
            item.mensagem = mensagens ? mensagens[idx] : mensagemPadrao

            response.mensagens.push(item)
          }
        } else {
          throw Error(
            "Para utilizar a validação 'equalsTo' o valor informado deve ser uma lista!"
          )
        }
        break

      case 'conditionalNotEmpty':
        const [value, condicao] = valor

        if (condicao) {
          if (!value || value.toString().trim() === '') {
            const mensagemPadrao = `O campo ${nomeCampos ? nomeCampos[idx] : ''
            } não pode ser vazio!`
            item.mensagem = mensagens ? mensagens[idx] : mensagemPadrao

            response.mensagens.push(item)
          }
        }
        break

      default:
        console.error(valores, validacoes, nomeCampos, mensagens)
        throw Error('Ops, houve um problema ao validar os campos!')
      }
    })

    if (response.mensagens.length > 0) {
      response.erro = true
    }

    return response
  };

  static validaEmail (email) {
    const regexp =
      /^([a-zA-Z0-9_\-.]+)@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    const emailValidator = new RegExp(regexp)

    return emailValidator.test(email)
  }

  static dateDiff (firstDate, secondDate, period = 'days') {
    return moment(firstDate).diff(secondDate, period)
  }

  /**
   * Procura um valor dentro de uma lista de objetos,
   * se esses objetos possuem modelos de si mesmo
   * @param {string | number | boolean} value Valor que será pesquisado
   * @param {Array<{}>} list Lista onde o valor será pesquisado
   * @param {string} keyValue Chave do valor pesquisado
   * @param {string} keyList Chave da lista interna
   * @returns O objeto que possui o valor encontrado e undefined em outros casos
   */
  static searchRecursively = (value, list, keyValue, keyList) => {
    if (
      !['string', 'number', 'boolean'].find((type) => type === typeof value)
    ) {
      throw new TypeError(
        'A propriedade value deve receber um texto, número ou booleano!'.concat(
          ' ',
          `Valor recebido '${value}'`
        )
      )
    }
    if (typeof list !== typeof []) {
      throw new TypeError(
        'A propriedade list deve receber uma lista!'.concat(
          ' ',
          `Valor recebido '${value}'`
        )
      )
    }

    if (typeof keyValue !== 'string') {
      throw new TypeError(
        'A propriedade keyValue deve receber um texto!'.concat(
          ' ',
          `Valor recebido '${value}'`
        )
      )
    }

    if (typeof keyList !== 'string') {
      throw new TypeError(
        'A propriedade keyList deve receber um texto!'.concat(
          ' ',
          `Valor recebido '${value}'`
        )
      )
    }

    for (const element of list) {
      if (element[keyValue] === value) {
        return element
      } else if (element[keyList]?.length) {
        const result = CommonHelper.searchRecursively(
          value,
          element[keyList],
          keyValue,
          keyList
        )
        if (result) return result
      }
    }
  };

  /**
   * Procura um valor dentro de uma lista de objetos,
   * @param {string | number | boolean} value Valor que será pesquisado
   * @param {Array<{}>} list Lista onde o valor será pesquisado
   * @param {string} keyValue Chave do valor pesquisado
   * @returns O objeto que possui o valor encontrado e undefined em outros casos
   */
  static searchInList = (value, list, keyValue) => {
    if (
      !['string', 'number', 'boolean'].find((type) => type === typeof value)
    ) {
      throw new TypeError(
        'A propriedade value deve receber um texto, número ou booleano!'.concat(
          ' ',
          `Valor recebido '${value}'`
        )
      )
    }

    if (typeof list !== typeof []) {
      throw new TypeError(
        'A propriedade list deve receber uma lista!'.concat(
          ' ',
          `Valor recebido '${value}'`
        )
      )
    }

    if (typeof keyValue !== 'string') {
      throw new TypeError(
        'A propriedade keyValue deve receber um texto!'.concat(
          ' ',
          `Valor recebido '${value}'`
        )
      )
    }

    for (const element of list) {
      if (element[keyValue] === value) {
        return element
      }
    }
  };

  static formatStringToURL = (string) => {
    if (string) {
      return string.toString().replaceAll('/', '%2f')
    }

    return ''
  };

  static formatURLtoString = (url) => {
    if (url) {
      return url.toString().replaceAll('%2f', '/')
    }

    return ''
  };

  static getPropertyFromObjectByNamePath (propertyFullPath, object) {
    const propertiesNames = propertyFullPath.split('.')
    const objectDepthLength = propertiesNames.length

    let propertyValue = object

    for (let objectDepth = 0; objectDepth < objectDepthLength; objectDepth++) {
      const propertyName = propertiesNames[objectDepth]
      propertyValue = propertyValue[propertyName]
    }

    return propertyValue
  }

  static formatStringToPascalCase (string) {
    const palavras = string.toLowerCase().split(' ')

    const palavrasFirstLetterUpperCase = palavras.map(
      (palavra) => palavra[0].toUpperCase() + palavra.substring(1)
    )

    let pascalCaseFormated = ''

    palavrasFirstLetterUpperCase.forEach(
      (palavraFirstLetterUpperCase) =>
        (pascalCaseFormated += palavraFirstLetterUpperCase)
    )

    return pascalCaseFormated
  }
}
